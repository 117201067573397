<template>
  <div>
    <div class="air__utils__heading">
      <h5>Laboratorio</h5>
    </div>
    <my-breadcrumbs :routes="routes"/>
    <!--    Enlaces-->
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'visualInspections'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="camera2" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'visualInspections'}">
              <h6 class="text-block text-secondary">Inspección Visual</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'sampleReceiptMain'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="calendar2-week" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'sampleReceiptMain'}">
              <h6 class="text-block text-secondary">Recepción de muestras</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'reagentsMaster'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="file-earmark-break" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'reagentsMaster'}">
              <h6 class="text-block text-secondary">Master de Reactivos</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'reagentMain'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="box-seam" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'reagentMain'}">
              <h6 class="text-block text-secondary">Almacén de Reactivos</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'sampleWarehouseMain'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="box-seam" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'sampleWarehouseMain'}">
              <h6 class="text-block text-secondary">Almacén de Muestras</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'testMaster'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="droplet-half" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'testMaster'}">
              <h6 class="text-block text-secondary">Master de Análisis</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'labAnalisis'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="ui-checks" />
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'labAnalisis'}">
              <h6 class="text-block text-secondary">Análisis de Laboratorio</h6>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-4 col-md-12">
        <div class="card">
          <div class="card-title">
            <h1 class="text-block mt-5 mb-4 text-center">
              <router-link :to="{ name: 'laboratoryEquipment'}">
                <b-iconstack font-scale="1" variant="primary">
                  <b-icon stacked icon="tools" scale="0.5" shift-v="8"/>
                  <b-icon stacked icon="box" shift-v="-3"/>
                </b-iconstack>
              </router-link>
            </h1>
          </div>
          <div class="card-body text-center">
            <router-link :to="{ name: 'laboratoryEquipment'}">
              <h6 class="text-block text-secondary">Equipos</h6>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!--    Enlaces-->
  </div>
</template>

<script>
export default {
  name: 'laboratoryMenu',
  data() {
    return {
      routes: [
        {
          name: 'home',
          breadcrumbName: 'Inicio',
          bIcon: 'house-fill',
        },
        {
          breadcrumbName: 'Laboratorio',
          aIcon: '',
        },
      ],
    }
  },
}
</script>

<style scoped>
.border-bottom {
  border-width:3px !important;
}
</style>
